import Vue, {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  ref,
  watch,
} from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { getList } from '@/api/selects'
import moment from 'moment/moment'
import store from '@/store'
import { useRouter, useRoute } from 'vue-router/composables'
import _ from 'lodash'
import useRequest from '@/compositions/useRequest'
import exPanel from '@/components/Contract/exPanel'
import Doljnost from '@/components/Contract/doljnost'
import Popup from '@/components/Popup/index.vue'
import Coefficient from '@/components/Contract/tariff/coefficient'
import Tariff from '@/components/Contract/tariff/individual'
// import versionChange from '@/components/Contract/types/x5/pact/dialog'
// import subversionChange from '@/components/Contract/types/x5/pact/version/dialog'
// import applicationChange from './dialog'
import plug from '@/components/Contract/plug'
import Version from '@/components/Contract/dialog/version'
import Application from '@/components/Contract/dialog/application'
import addEntity from '@/components/Contract/dialog/addEntity'
// import historyChange from '@/pages/object/config/custom-object-tarif/historyChange'

export default defineComponent({
  props: {},
  components: {
    exPanel,
    Popup,
    Doljnost,
    Tariff,
    Coefficient,
    Version,
    Application,
    addEntity,
    plug,
  },
  setup(props, ctx) {
    const { emit } = ctx
    const route = useRoute()
    const router = useRouter()
    const context = {
      root: {
        store,
        router,
        ctx,
        route,
      },
    }

    const magnitBtn = [
      { name: 'Договоры', value: 0 },
      { name: 'Округа', value: 1 },
    ]

    const contractBtn = [
      { name: 'Доп. соглашение', value: 0 },
      { name: 'Приложение', value: 1 },
    ]

    let controller
    const getMagnitType = async () => {
      if (controller) controller.abort()
      controller = new AbortController()
      magnit.value.loading = true
      let response
      try {
        if (magnit.value.docType === 0) {
          response = await store.dispatch('form/getParams', {
            url: 'get/contract/magnit',
            params: {
              signal: controller.signal,
            },
          })
          response.data.forEach((item) => {
            Vue.set(item, 'loaded', null)
            Vue.set(item, 'items', null)
          })
          magnit.value.items = response.data
        } else if (magnit.value.docType === 1) {
          const list = 'magnit_district'
          response = await store.dispatch('form/update', {
            url: 'get/lists',
            body: [{ alias: list, filter: [] }],
            params: {
              signal: controller.signal,
            },
          })
          response.data[list].forEach((item) => {
            Vue.set(item, 'loaded', false)
            Vue.set(item, 'items', null)
          })
          magnit.value.items = response.data[list]
          if (response.data[list][0])
            magnit.value.activeItems = [response.data[list][0]]
          getActiveFillial()
        }
      } catch (e) {
        return e
      }
      controller = undefined
      magnit.value.loading = false
    }

    const getVersionDocType = async (version) => {
      version.loading = true
      const response = await store.dispatch(
        'form/get',
        `get/contract/versions/${
          version.docType === 0 ? 'additional' : 'application'
        }/${version.id}`
      )
      version.items = response.data
      version.loading = false
    }

    const getFillial = async ({ item, refresh }) => {
      if (item.items && !refresh) return
      item.loaded = true
      const response = await store.dispatch('list/get', [
        {
          alias: 'magnit_filial',
          filter: [
            {
              alias: 'district_id',
              value: [item.id],
            },
          ],
        },
      ])
      response.data.magnit_filial.forEach((item) => {
        Vue.set(item, 'loaded', null)
        Vue.set(item, 'items', null)
      })
      item.items = response.data.magnit_filial
      item.loaded = false
    }

    const getActiveFillial = () => {
      magnit.value.activeItems.forEach((item) => {
        getFillial({ item })
      })
    }

    const magnit = ref({
      items: [],
      loading: false,
      docType: 0,
      activeItems: [],
      lastTarget: 0,
      refresh: getMagnitType,
    })

    const changeSelection = ({
      index,
      btn,
      item,
      activeItems,
      entity,
      items,
      request,
    }) => {
      if (btn === 'shift') {
        if (activeItems.some((x) => x.id === item.id)) {
          if (entity.lastTarget > index) {
            for (
              let i = entity.lastTarget;
              i >= index && activeItems.length > 1;
              i--
            ) {
              activeItems.splice(
                activeItems.findIndex((x) => x.id === items[i].id),
                1
              )
            }
          } else {
            for (
              let i = entity.lastTarget;
              i <= index && activeItems.length > 1;
              i++
            ) {
              activeItems.splice(
                activeItems.findIndex((x) => x.id === items[i].id),
                1
              )
            }
          }
        } else {
          if (entity.lastTarget > index) {
            for (let i = entity.lastTarget; i >= index; i--) {
              if (!activeItems.some((x) => x.id === items[i].id)) {
                activeItems.push(items[i])
              }
            }
          } else {
            for (let i = entity.lastTarget; i <= index; i++) {
              if (!activeItems.some((x) => x.id === items[i].id)) {
                activeItems.push(items[i])
              }
            }
          }
        }
      } else if (btn === 'ctrl') {
        if (
          activeItems.some((x) => x.id === item.id) &&
          activeItems.length > 1
        ) {
          activeItems.splice(index, 1)
        } else {
          activeItems.push(item)
        }
      } else {
        activeItems.splice(0)
        Vue.set(activeItems, 0, item)
      }
      entity.lastTarget = index
      items.forEach((item) => {
        if (!activeItems.some((x) => x.id === item.id))
          Vue.set(item, 'items', null)
      })
      nextTick(() => request(entity))
    }

    const getSubfillial = async (item) => {
      return await store.dispatch('list/get', [
        {
          alias: 'magnit_city',
          filter: [
            {
              alias: 'filial_id',
              value: [item.id],
            },
          ],
        },
      ])
    }

    const getVersions = async (item) => {
      return await store.dispatch(
        'form/get',
        `get/contract/versions/${item.id}`
      )
    }

    const getFormat = async (item) => {
      return await store.dispatch('list/get', [
        {
          alias: 'magnit_format',
          filter: [
            {
              alias: 'city_id',
              value: [item.id],
            },
            {
              alias: 'filial_id',
              value: [1],
            },
          ],
        },
      ])
    }

    const initFormat = (item) => {
      item.activeItems.push(item.items[0])
      getActiveFormat(item)
    }

    const getActiveFormat = (format) => {
      format.activeItems.forEach((item) => {
        getFormatItem({ item, format })
      })
    }

    const getFormatItem = async ({ item, format, refresh }) => {
      if (item.items && !refresh) return
      item.loaded = true
      const response = await store.dispatch('list/get', [
        {
          alias: 'magnit_object_by_format',
          filter: [
            {
              alias: 'city_id',
              value: [format.id],
            },
            {
              alias: 'format_id',
              value: [item.id],
            },
          ],
        },
      ])
      response.data.magnit_object_by_format.forEach((item) => {
        Vue.set(item, 'loaded', null)
        Vue.set(item, 'items', null)
      })
      item.items = response.data.magnit_object_by_format
      item.loaded = false
    }

    const getObject = async (item) => {
      return await store.dispatch('list/get', [
        {
          alias: 'active_doljnost_by_object',
          filter: [
            {
              alias: 'object_id',
              value: [item.id],
            },
          ],
        },
      ])
    }

    const createContract = async ({ formData }) => {
      return await store.dispatch(`form/create`, {
        url: 'create/contract',
        body: {
          data: {
            name: formData.name,
            type_object_id: 2,
          },
        },
      })
    }
    const manageDistrict = async ({ formData }) => {
      return await store.dispatch(
        `form/${formData.id ? 'putForm' : 'create'}`,
        {
          url: `${formData.id ? 'update' : 'create'}/magnit_district`,
          body: {
            data: {
              name: formData.name,
              id: formData.id,
            },
          },
        }
      )
    }

    const manageFillial = async ({ formData }) => {
      return await store.dispatch(
        `form/${formData.id ? 'putForm' : 'create'}`,
        {
          url: `${formData.id ? 'update' : 'create'}/magnit_filial`,
          body: {
            data: {
              name: formData.name,
              id: formData.id,
              district_id: formData.district_id,
            },
          },
        }
      )
    }

    const manageSubfillial = async ({ formData }) => {
      return await store.dispatch(
        `form/${formData.id ? 'putForm' : 'create'}`,
        {
          url: `${formData.id ? 'update' : 'create'}/magnit_subfilial`,
          body: {
            data: {
              name: formData.name,
              id: formData.id,
              filial_id: formData.filial_id,
            },
          },
        }
      )
    }

    const refreshPanel = (item) => {
      item.refresh()
    }

    const convertDate = (val) => {
      return moment(val, 'YYYY-MM-DD').format('DD.MM.YYYY')
    }

    const download = (url) => {
      Vue.downloadFile(url)
    }

    watch(
      () => magnit.value.docType,
      () => {
        getMagnitType()
      },
      { immediate: true }
    )

    return {
      magnitBtn,
      contractBtn,
      magnit,
      // form,
      // dialog,

      changeSelection,
      getFillial,
      getSubfillial,
      // addEntity,
      refreshPanel,
      getActiveFillial,
      getFormat,
      initFormat,
      getActiveFormat,
      // confirmChanges,
      getMagnitType,
      getFormatItem,
      getObject,
      getVersions,
      getVersionDocType,

      // addVersion,

      convertDate,
      download,

      createContract,
      manageDistrict,
      manageFillial,
      manageSubfillial,
    }
  },
})
