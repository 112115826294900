import Vue, { onMounted, computed, ref, watch, toRef } from 'vue'
import store from '@/store'
import moment from 'moment/moment'
import _ from 'lodash'
import exPanel from '@/components/Contract/exPanel'
import Coefficient from '@/components/Contract/tariff/coefficient'
import Doljnost from '@/components/Contract/doljnost'
import Tariff from '@/components/Contract/tariff/individual'
import plug from '@/components/Contract/plug'
import Version from '@/components/Contract/dialog/version'

export default {
  name: 'Pact',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    territory: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    exPanel,
    // Dialog,
    Coefficient,
    Doljnost,
    Tariff,
    plug,
    Version,
  },
  setup(props, ctx) {
    const { emit } = ctx
    const getVersions = async (item) => {
      return await store.dispatch(
        'form/get',
        `get/contract/versions/${item.id}`
      )
    }

    const convertDate = (val) => {
      return moment(val, 'YYYY-MM-DD').format('DD.MM.YYYY')
    }

    const download = (url) => {
      Vue.downloadFile(url)
    }

    return {
      getVersions,

      download,
      convertDate,
    }
  },
}
