import { defineComponent } from 'vue'

const textInfo = defineComponent({
  name: 'TextInfo',
  props: {
    infoObj: {
      type: Object,
    },
    context: {
      type: Object,
    },
  },
  setup(props) {
    const checkVisibility = (item) => {
      if (item.isShow) {
        return !!item.isShow(props.context)
      } else return true
    }

    return {
      checkVisibility,
    }
  },
})
export default textInfo
