import Vue, {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  ref,
  watch,
} from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { getList } from '@/api/selects'
import moment from 'moment/moment'
import store from '@/store'
import { useRouter, useRoute } from 'vue-router/composables'
import _ from 'lodash'
import useRequest from '@/compositions/useRequest'
import zoneAdd from '@/pages/x5zone/config/custom-zone-add-edit'
import territoryAdd from '@/pages/x5territories/config/form-territories-add-edit'
// import exPanel from './../exPanel'
// import Doljnost from './../doljnost'
import Popup from '@/components/Popup/index.vue'
import Coefficient from '@/components/Contract/tariff/coefficient'
import Tariff from '@/components/Contract/tariff/individual'
// import versionChange from './../pact/dialog'
// import applicationChange from './dialog'
import plug from '@/components/Contract/plug'
import pact from './pact'
import zone from './zone'
import addEntity from '@/components/Contract/dialog/addEntity'

export default defineComponent({
  props: {},
  components: {
    Popup,
    plug,
    pact,
    zone,
    Tariff,
    Coefficient,
    addEntity,
  },
  setup(props, ctx) {
    const { emit } = ctx
    const route = useRoute()
    const router = useRouter()
    const context = {
      root: {
        store,
        router,
        ctx,
        route,
      },
    }

    const switchBtn = [
      { name: 'Договоры', value: 0 },
      { name: 'Зона', value: 1 },
    ]

    const getTerritoriesRequest = async () => {
      x5.value.loading = true
      const response = await store.dispatch('form/get', `get/territories/1`)
      x5.value.items = response.data
      x5.value.loading = false
      if (response.data[0]) {
        x5.value.activeItems = [response.data[0]]
        getActiveTerritories()
      }
      getList()
    }

    const x5 = ref({
      items: [],
      loading: false,
      docType: 0,
      activeItems: [],
      lastTarget: 0,
      refresh: getTerritoriesRequest,
    })

    const changeDocType = (index) => {
      if (x5.value.docType !== index) {
        x5.value.docType = index
        getActiveTerritories()
      }
    }

    const getActiveTerritories = () => {
      x5.value.activeItems.forEach((item) => {
        getTerritoryItem({ item })
      })
    }

    const createX5Territory = async ({ formData }) => {
      return await store.dispatch(`form/create`, {
        url: 'create/x5/territories',
        body: {
          data: {
            name: formData.name,
          },
        },
      })
    }

    const createContract = async ({ formData }) => {
      return await store.dispatch(`form/create`, {
        url: 'create/contract',
        body: {
          data: {
            name: formData.name,
            territory_id: formData.territory_id,
            type_id: formData.type_id,
            type_object_id: 1,
          },
        },
      })
    }

    const createX5Zone = async ({ formData }) => {
      return await store.dispatch(`form/create`, {
        url: 'create/x5/zone',
        body: {
          data: {
            name: formData.name,
            territory_id: formData.territory_id,
          },
        },
      })
    }

    const getTerritoryItem = async ({ item, refresh = false }) => {
      if (x5.value.docType === item.docType && item.items && !refresh) {
        return
      }
      Vue.set(item, 'loading', true)
      const response = await store.dispatch('form/getParams', {
        url: `get/${x5.value.docType === 0 ? 'contract' : 'zones'}/${item.id}`,
      })
      response.data.forEach((item) => {
        Vue.set(item, 'loaded', null)
        Vue.set(item, 'items', null)
      })
      Vue.set(item, 'items', response.data)
      item.loading = false
    }

    const changeSelection = ({
      index,
      btn,
      item,
      activeItems,
      entity,
      items,
      request,
    }) => {
      if (btn === 'shift') {
        if (activeItems.some((x) => x.id === item.id)) {
          if (entity.lastTarget > index) {
            for (
              let i = entity.lastTarget;
              i >= index && activeItems.length > 1;
              i--
            ) {
              activeItems.splice(
                activeItems.findIndex((x) => x.id === items[i].id),
                1
              )
            }
          } else {
            for (
              let i = entity.lastTarget;
              i <= index && activeItems.length > 1;
              i++
            ) {
              activeItems.splice(
                activeItems.findIndex((x) => x.id === items[i].id),
                1
              )
            }
          }
        } else {
          if (entity.lastTarget > index) {
            for (let i = entity.lastTarget; i >= index; i--) {
              if (!activeItems.some((x) => x.id === items[i].id)) {
                activeItems.push(items[i])
              }
            }
          } else {
            for (let i = entity.lastTarget; i <= index; i++) {
              if (!activeItems.some((x) => x.id === items[i].id)) {
                activeItems.push(items[i])
              }
            }
          }
        }
      } else if (btn === 'ctrl') {
        if (
          activeItems.some((x) => x.id === item.id) &&
          activeItems.length > 1
        ) {
          activeItems.splice(index, 1)
        } else {
          activeItems.push(item)
        }
      } else {
        activeItems.splice(0)
        Vue.set(activeItems, 0, item)
      }
      entity.lastTarget = index
      items.forEach((item) => {
        if (!activeItems.some((x) => x.id === item.id))
          Vue.set(item, 'items', null)
      })
      nextTick(() => request(entity))
    }

    const { makeRequest: makeRequestList } = useRequest({
      context,
      request: () =>
        store.dispatch('list/get', [
          { alias: 'agreement_dictionary', filter: [] },
          { alias: 'x5_territories', filter: [] },
        ]),
    })

    const list = ref()
    const getList = async () => {
      const response = await makeRequestList()
      list.value = response.data
    }

    onMounted(() => {
      getTerritoriesRequest()
    })

    return {
      switchBtn,
      changeSelection,
      x5,
      getActiveTerritories,
      changeDocType,
      getTerritoryItem,
      createX5Territory,
      createX5Zone,
      createContract,
      list,
    }
  },
})
