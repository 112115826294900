var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticStyle:{"padding":"10px"}},[_c('v-card-title',{staticClass:"py-1 justify-center font-weight-bold text-h6"},[_vm._v(" Начисление  "),_c('a',{staticClass:"text-h6",on:{"click":function($event){return _vm.openPayment(_vm.data.entity.id)}}},[_vm._v("№"+_vm._s(_vm.data.entity.id))]),_vm._v("  на дату "+_vm._s(_vm.convertDate(_vm.data.entity.date_target))+" ")]),_c('TextInfo',{attrs:{"context":_vm.context,"infoObj":_vm.infoObj}}),(_vm.directionToMagnit)?_c('div',[_c('span',{staticStyle:{"font-weight":"600"}},[_vm._v("Путевой лист:")]),(_vm.pathAct)?_c('div',[_c('a',{attrs:{"download":"","href":_vm.$root.env.VUE_APP_STORE + _vm.pathAct}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":_vm.$root.env.VUE_APP_STORE + _vm.pathAct,"alt":"#"}})])]):_c('span',[_vm._v(" Не приложен")])]):_vm._e(),_c('span',{staticClass:"font-weight-bold mb2"},[(_vm.dropzone.length)?_c('v-icon',{attrs:{"small":""}},[_vm._v("$IconGalka")]):_vm._e(),_vm._v("Приложите документы подтверждающие смену: ")],1),_c('Dropzone',{staticClass:"mt-2",attrs:{"options":{
        withoutSave: false,
        folder: 'magnit_path_act',
        removeble: true,
        countFiles: 1,
        type: ['pdf', 'png', 'jpg', 'jpeg'],
      }},model:{value:(_vm.dropzone),callback:function ($$v) {_vm.dropzone=$$v},expression:"dropzone"}})],1),_c('v-divider'),_c('v-row',{staticClass:"py-2",attrs:{"justify":"end"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"loading":_vm.loading,"small":"","color":"primary","disabled":!_vm.dropzone.length},on:{"click":_vm.endTask}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-save")]),_vm._v(" Завершить ")],1),_c('v-btn',{attrs:{"loading":_vm.loading,"small":"","color":"blue-grey"},on:{"click":function($event){return _vm.$emit('closePopup')}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")]),_vm._v(" Закрыть ")],1)],1),(_vm.config.detail && _vm.config.detail.type === 'popup' && _vm.popupForm.isShow)?_c('Popup',{attrs:{"options":{
      width: _vm.config.detail.width,
      portal: 'table-detail',
    }}},[_c('router-view',{class:[..._vm.config.detail.bootstrapClass, ..._vm.config.detail.classes],attrs:{"detail":_vm.config.detail},on:{"closePopup":_vm.closePopupForm,"refreshData":function($event){return _vm.$emit('refreshData')}}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }