import Vue, { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { getList } from '@/api/selects'
import moment from 'moment/moment'
import store from '@/store'
import { useRouter, useRoute } from 'vue-router/composables'
import _ from 'lodash'
import useRequest from '@/compositions/useRequest'
import Datepicker from '@/components/Date/Default/index.vue'
import ChangeMenu from './../changeMenu'

export default defineComponent({
  props: {
    tariff: {
      type: Boolean,
      default: false,
    },
    requestData: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    ChangeMenu,
  },
  setup(props, ctx) {
    const { emit } = ctx
    const route = useRoute()
    const router = useRouter()

    return {}
  },
})
