import Vue, { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { getList } from '@/api/selects'
import moment from 'moment/moment'
import store from '@/store'
import _ from 'lodash'
import { useRouter, useRoute } from 'vue-router/composables'
import useRequest from '@/compositions/useRequest'
import Datepicker from '@/components/Date/Default/index.vue'

export default defineComponent({
  props: {
    action: {
      type: Object,
      default: () => {},
    },
    data: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Datepicker,
  },
  setup(props, ctx) {
    const { emit } = ctx
    const route = useRoute()
    const router = useRouter()

    const fields = {
      sum: null,
      date_from: null,
      date_to: null,
    }
    const formData = ref(_.cloneDeep(fields))
    const acceptDisable = ref(true)
    const loading = ref(false)

    const menu = ref(false)

    const convertDate = (val) => {
      return moment(val, 'YYYY-MM-DD').format('DD.MM.YYYY')
    }
    const getUnix = (val) => {
      return moment(val, 'YYYY.MM.DD').unix()
    }

    const accept = async () => {
      loading.value = true
      const date_from = moment(formData.value.date_from, 'YYYY.MM.DD').format(
        'YYYY-MM-DD'
      )
      const date_to = moment(formData.value.date_to, 'YYYY.MM.DD').format(
        'YYYY-MM-DD'
      )
      let response
      try {
        if (props.action.type === 'edit') {
          response = await store.dispatch('form/putForm', {
            url: 'individual_price/edit',
            body: {
              data: {
                date_from,
                date_to,
                id: props.data.id,
                sum: Number(formData.value.sum),
              },
            },
          })
        } else if (props.action.type === 'prolongation') {
          response = await store.dispatch('form/update', {
            url: 'individual_price/prolongation',
            body: {
              data: {
                date_to,
                id: props.data.id,
              },
            },
          })
        } else if (props.action.type === 'off') {
          response = await store.dispatch('form/delForm', {
            url: 'individual_price/off',
            body: {
              data: {
                id: props.data.id,
              },
            },
          })
        }
      } finally {
        loading.value = false
        if (response.code === 1) {
          emit('refresh')
          menu.value = false
        } else {
          store.commit('notifies/showMessage', {
            color: 'error',
            content: 'Ошибка сервера',
            timeout: 3000,
          })
        }
      }
    }

    watch(
      () => menu.value,
      () => {
        if (menu.value) {
          formData.value = _.cloneDeep(fields)
          if (props.action.type === 'prolongation') {
            formData.value.date_from = props.data.date_to
          }
        }
      }
    )

    watch(
      () => formData.value,
      () => {
        if (props.action.type === 'prolongation') {
          if (
            formData.value.date_from &&
            formData.value.date_to &&
            getUnix(formData.value.date_to) >= getUnix(formData.value.date_from)
          ) {
            acceptDisable.value = false
          } else acceptDisable.value = true
        } else if (props.action.type === 'edit') {
          if (
            formData.value.date_from &&
            formData.value.date_to &&
            getUnix(formData.value.date_to) >=
              getUnix(formData.value.date_from) &&
            Number(formData.value.sum)
          ) {
            acceptDisable.value = false
          } else acceptDisable.value = true
        }
      },
      { deep: true }
    )
    return {
      convertDate,
      menu,

      formData,
      loading,
      accept,
      acceptDisable,
    }
  },
})
