import Vue, { onMounted, computed, ref, watch, toRef } from 'vue'
import store from '@/store'
import _ from 'lodash'
import exPanel from '@/components/Contract/exPanel'
import Coefficient from '@/components/Contract/tariff/coefficient'
import Doljnost from '@/components/Contract/doljnost'
import Tariff from '@/components/Contract/tariff/individual'
import plug from '@/components/Contract/plug'

export default {
  name: 'Zone',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Coefficient,
    Tariff,
    plug,
    // history,
    exPanel,
    Doljnost,
  },
  setup(props, ctx) {
    const { emit } = ctx

    const getCitiesByZone = async (item) => {
      return await store.dispatch('list/get', [
        {
          alias: 'x5_zone_city',
          filter: [
            {
              alias: 'zone_id',
              value: [item.id],
            },
          ],
        },
      ])
    }

    const getObjectByCity = async (item) => {
      return await store.dispatch('list/get', [
        {
          alias: 'object_by_city',
          filter: [
            {
              alias: 'city_id',
              value: [item.id],
            },
          ],
        },
      ])
    }

    const getDoljnostByObject = async (item) => {
      return await store.dispatch('list/get', [
        {
          alias: 'active_doljnost_by_object',
          filter: [
            {
              alias: 'object_id',
              value: [item.id],
            },
          ],
        },
      ])
    }

    const getObjectByZone = async (item) => {
      const response = await store.dispatch('list/get', [
        {
          alias: 'active_doljnost_by_zone',
          filter: [
            {
              alias: 'zone_id',
              value: [item.id],
            },
          ],
        },
      ])
      Vue.set(item, 'doljnostArray', response.data.active_doljnost_by_zone)
    }

    return {
      getCitiesByZone,
      getObjectByCity,
      getDoljnostByObject,
      getObjectByZone,
    }
  },
}
