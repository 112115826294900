import filters from './filters'
import filtersKey from './filtersKey'

import { stringAction } from '@/utils/actions'
import _ from 'lodash'

import tablePersonalDebt from './config/table-personal-debt.js'
import tablePersonalBank from './config/table-personal-bank.js'
import tablePersonalScan from './config/table-personal-scan.js'
import formPersonalEdit from './config/form-personal-edit.js'
import formPersonalAdd from './config/form-personal-add.js'
import personalSelectAccount from './config/form-personal-select-account.js'
import tablePersonalX5 from './config/table-personal-x5.js'
import tablePersonalEmployment from './config/table-personal-employment.js'
import formPersonalDocs from './config/form-personal-docs.js'
import formPersonalDirection from './config/form-personal-direction.js'
import formDocumentDownload from './config/form-document-download.js'
import formPersonalParser from './config/form-personal-parser.js'
import formPersonalEmployment from './config/form-personal-employment'
import customPersonalTransmit from './config/custom-personal-transmit'

import formKeyAdd from './config/form-key-add.js'
import formKeyEdit from './config/form-key-edit.js'

import formBind from './config/form-bind.js'
import formLoad from './config/form-load.js'

function consoleText(row) {
  //return 'test'
}
function consoleButton(row) {}
function consolePanel() {}
function searchInputing(field) {}

const massAction = async (context, button) => {
  const data = await context.store.dispatch('form/putForm', {
    url: `mass/${context?.options?.options?.tableName}/${button.action}`,
    body: { data: { ids: context.idArray } },
  })
  if (data.code === 1) {
    return { result: data.result, action: button.action }
  }
  if (data.code === 2) {
    context.store.commit('notifies/showMessage', {
      color: 'warning',
      content: 'Не выбрано ни одного начисления',
      timeout: 2000,
    })
  } else if (data.code === 3) {
    context.store.commit('notifies/showMessage', {
      color: 'error',
      content: 'Что-то пошло не так...',
      timeout: 2000,
    })
  } else if (data.code === 4) {
    context.store.commit('notifies/showMessage', {
      color: 'error',
      content: 'Нет доступа',
      timeout: 2000,
    })
  }
}

const nonExportTabs = [
  formLoad,
  formBind,
  formPersonalAdd,
  personalSelectAccount,
  formPersonalDirection,
  formPersonalEmployment,
  tablePersonalEmployment,
  formPersonalParser,
]

export const personalTabs = [
  formPersonalEdit,
  formPersonalDocs,
  tablePersonalScan,
  tablePersonalBank,
  tablePersonalX5,
  tablePersonalDebt,
  formDocumentDownload,
]

const contextMenuPersonal = {
  actions: [
    {
      icon: 'mdi-plus',
      label: 'Привязать объект',
      isShow: {
        condition: [
          {
            permissions: [1, 3, 15, 4],
            type: true,
          },
          {
            funcCondition: (context) => {
              const directions = context.store.state.user.direction_json
              return (
                directions.length === 1 &&
                (directions.includes(7) || directions.includes(2))
              )
            },
            type: false,
          },
        ],
      },
      action: {
        type: 'changeUrl',
        target: 'id',
        url: 'personal/bind',
      },
    },
    {
      icon: 'mdi-plus',
      label: 'Добавить направ-ие',
      isShow: {
        condition: [
          {
            permissions: [1, 3, 15, 4],
            type: true,
          },
          {
            funcCondition: (context) => {
              const directions = context.store.state.user.direction_json
              return (
                directions.length === 1 &&
                (directions.includes(7) || directions.includes(2))
              )
            },
            type: false,
          },
        ],
      },
      readonly: {
        value: false,
        condition: [
          {
            is_personal_vertical: [true],
            type: true,
          },
          {
            permissions: [13],
            type: true,
          },
        ],
      },
      action: {
        type: 'changeUrl',
        target: 'id',
        url: 'personal/direction',
      },
    },
  ],
}

export const config = {
  title: 'Персонал',
  activeTab: 2,
  tabs: [
    {
      selector: '#mainTable',
      options: {
        selecting: true,
        search: {
          function: searchInputing,
        },
        headerFixed: true,
        url: 'get/pagination/personal_active',
        title: 'Основные',
        tableName: 'personal_active',
        contextMenu: contextMenuPersonal,
      },
      type: 'TableDefault',
      panel: {
        buttons: [
          {
            label: 'Обновить',
            class: ['v-table-button--custom'],
            url: '$IconEdit',
            function: consolePanel,
            backgroundColor: '#ffffff',
          },
          {
            label: 'Добавить персонал',
            class: ['v-table-button--custom'],
            type: 'changeUrl',
            url: 'personal-add',
            backgroundColor: '#fff',
            isShow: {
              condition: [
                // {
                //   permissions: [1, 9],
                //   type: true,
                // },
                {
                  funcCondition: (ctx) => {
                    if ([1, 9].includes(ctx.store.state.user.permission_id)) {
                      return true
                    } else if (
                      [15].includes(ctx.store.state.user.permission_id) &&
                      ctx.store.state.user.direction_json.includes(7)
                    ) {
                      return true
                    }
                    return false
                  },
                  type: true,
                },
              ],
            },
          },
          {
            label: 'Выгрузка реестра',
            class: ['v-table-button--custom'],
            type: 'changeUrl',
            url: 'personal/employment',
            isShow: {
              condition: [
                {
                  permissions: [4, 12, 22],
                  type: true,
                },
              ],
            },
            backgroundColor: '#fff',
          },
          {
            label: 'Парсер реестра',
            class: ['v-table-button--custom'],
            type: 'changeUrl',
            url: 'personal/employment_parser',
            backgroundColor: '#fff',
            isShow: {
              condition: [
                {
                  permissions: [4, 12, 22],
                  type: true,
                },
              ],
            },
          },
          {
            label: 'Передача',
            class: ['v-table-button--custom'],
            type: 'changeUrl',
            url: 'personal/transmit',
            backgroundColor: '#fff',
            isShow: {
              condition: [
                {
                  funcCondition: (ctx) => {
                    return (
                      ctx.store.state.user.is_chief &&
                      ctx.store.state.user.is_personal_vertical &&
                      ctx.store.state.user.direction_json.includes(2)
                    )
                  },
                  type: true,
                },
              ],
            },
          },
        ],
      },
      head: [
        {
          title: 'id',
          type: 'default',
          align: 'center',
          fixed: {
            value: false,
            position: 'left',
          },
          sorts: [
            {
              type: 'string',
              default: '',
              value: '',
              isShow: false,
            },
          ],
          alias: 'p.id',
          isShow: true,
          width: '40',
          value: 'id',
          search: {
            field: '',
            isShow: true,
          },
        },
        {
          title: 'Статус',
          type: 'default',
          align: 'center',
          fixed: {
            value: false,
            position: 'left',
          },
          sorts: [
            {
              type: 'text',
              default: '',
              value: '',
              isShow: false,
            },
          ],
          isShow: true,
          width: '90',
          alias: 'ps.status',
          value: 'status_name',
          search: {
            field: '',
            isShow: true,
          },
        },
        {
          title: 'ФИО',
          type: 'default',
          align: 'center',
          fixed: {
            value: false,
            position: 'left',
          },
          sorts: [
            {
              type: 'string',
              default: '',
              value: '',
              isShow: false,
            },
          ],
          isShow: true,
          width: '150',
          alias: "CONCAT(p.surname, ' ', p.name_n, ' ', p.patronymic)",
          value: 'fio',
          search: {
            field: '',
            isShow: true,
          },
        },
        {
          title: 'Телефон',
          type: 'default',
          align: 'center',
          fixed: {
            value: false,
            position: undefined,
          },
          sorts: [
            {
              type: 'text',
              default: '',
              value: '',
              isShow: false,
            },
          ],
          isShow: true,
          width: '150',
          value: 'telefon',
          alias: 'p.telefon',
          search: {
            field: '',
            isShow: true,
          },
        },
        {
          title: 'Примечание',
          type: 'default',
          align: 'center',
          fixed: {
            value: false,
            position: undefined,
          },
          sorts: [
            {
              type: 'text',
              default: '',
              value: '',
              isShow: false,
            },
          ],
          isShow: true,
          width: '150',
          value: 'comment',
          alias: 'p.comment',
          search: {
            field: '',
            isShow: true,
          },
        },
        {
          title: 'Действия',
          type: 'actions',
          align: 'center',
          fixed: {
            value: false,
            position: 'right',
          },
          isShow: true,
          width: '100',
          value: 'actions',
          sorts: [],
          actions: [
            {
              type: 'button',
              url: '$IconSetting',
              function: consoleText,
              label: 'Редактировать',
            },
            {
              type: 'button',
              url: '$IconSetting',
              function: consoleButton,
              label: 'Удалить',
            },
          ],
        },
      ],
      bottomPanel: {
        buttons: [
          {
            text: 'Добавить',
            action: 'add',
            type: 'changeUrl',
            color: 'green',
            url: 'personal-select-account',
          },
        ],
        isShow: true,
      },
      data: {
        rows: [],
        totalRows: null,
        pageLength: 20,
        currentPage: 1,
        totalPages: null,
      },
      detail: {
        type: 'popup', // String 'popup' or 'page'
        classes: [''], // List class
        width: '1000px',
        method: 'get',
        alias: 'personal',
        url: '/get/form/',
        name: 'Персонал',
        bootstrapClass: [''], // List class from bootstrap ( col-6, pa-2... )
        tabs: [...personalTabs, ...nonExportTabs, customPersonalTransmit],
        clearStore: true,
        activeTab: null,
        formData: {},
      },
      filters: _.cloneDeep(filters),
    },
    {
      selector: '#mainTable',
      options: {
        selecting: true,
        search: {
          function: searchInputing,
        },
        headerFixed: true,
        //url: 'https://dummyjson.com/users',
        url: 'get/pagination/personal_passive',
        title: 'Пассив',
        contextMenu: contextMenuPersonal,
      },
      type: 'TableDefault',
      panel: {
        buttons: [
          {
            label: 'Обновить',
            class: ['v-table-button--custom'],
            url: '$IconEdit',
            function: consolePanel,
            backgroundColor: '#ffffff',
          },
          // {
          //   label: 'Добавить',
          //   class: ['v-table-button--custom'],
          //   url: '$IconSetting',
          //   function: consolePanel,
          //   backgroundColor: '#fff',
          // },
          // {
          //   label: 'Скачать',
          //   class: ['v-table-button--custom'],
          //   function: consolePanel,
          //   backgroundColor: '#fff',
          // },
          //{
          //  label: 'Добавить персонал',
          //  class: ['v-table-button--custom'],
          //  function: consolePanel,
          //  backgroundColor: '#fff',
          //},
          //{
          //  label: 'Добавить персонал',
          //  class: ['v-table-button--custom'],
          //  type: 'changeUrl',
          //  url: 'personal-add',
          //  backgroundColor: '#fff',
          //  isShow: {
          //    condition: [
          //      {
          //        permissions: [1],
          //        type: true,
          //      },
          //    ]
          //  }
          //},
        ],
      },
      head: [
        {
          title: 'id',
          type: 'default',
          align: 'center',
          fixed: {
            value: false,
            position: 'left',
          },
          sorts: [
            {
              type: 'string',
              default: '',
              value: '',
              isShow: false,
            },
          ],
          alias: 'p.id',
          isShow: true,
          width: '40',
          value: 'id',
          search: {
            field: '',
            isShow: true,
          },
        },
        {
          title: 'Статус',
          type: 'default',
          align: 'center',
          fixed: {
            value: false,
            position: 'left',
          },
          sorts: [
            {
              type: 'text',
              default: '',
              value: '',
              isShow: false,
            },
          ],
          isShow: true,
          width: '90',
          alias: 'ps.status',
          value: 'status_name',
          search: {
            field: '',
            isShow: true,
          },
        },
        {
          title: 'ФИО',
          type: 'default',
          align: 'center',
          fixed: {
            value: false,
            position: 'left',
          },
          sorts: [
            {
              type: 'string',
              default: '',
              value: '',
              isShow: false,
            },
          ],
          isShow: true,
          width: '150',
          alias: "CONCAT(p.surname, ' ', p.name_n, ' ', p.patronymic)",
          value: 'fio',
          search: {
            field: '',
            isShow: true,
          },
        },
        {
          title: 'Телефон',
          type: 'default',
          align: 'center',
          fixed: {
            value: false,
            position: undefined,
          },
          sorts: [
            {
              type: 'text',
              default: '',
              value: '',
              isShow: false,
            },
          ],
          isShow: true,
          width: '150',
          value: 'telefon',
          alias: 'p.telefon',
          search: {
            field: '',
            isShow: true,
          },
        },
        {
          title: 'Примечание',
          type: 'default',
          align: 'center',
          fixed: {
            value: false,
            position: undefined,
          },
          sorts: [
            {
              type: 'text',
              default: '',
              value: '',
              isShow: false,
            },
          ],
          isShow: true,
          width: '150',
          value: 'comment',
          alias: 'p.comment',
          search: {
            field: '',
            isShow: true,
          },
        },
        {
          title: 'Действия',
          type: 'actions',
          align: 'center',
          fixed: {
            value: false,
            position: 'right',
          },
          isShow: true,
          width: '100',
          value: 'actions',
          actions: [
            {
              type: 'button',
              url: '$IconSetting',
              function: consoleText,
              label: 'Редактировать',
            },
            {
              type: 'button',
              url: '$IconSetting',
              function: consoleButton,
              label: 'Удалить',
            },
          ],
        },
      ],
      data: {
        rows: [],
        totalRows: null,
        pageLength: 20,
        currentPage: 1,
        totalPages: null,
      },
      detail: {
        type: 'popup', // String 'popup' or 'page'
        classes: [''], // List class
        width: '1000px',
        method: 'get',
        alias: 'personal',
        url: '/get/form/',
        name: 'Персонал',
        bootstrapClass: [''], // List class from bootstrap ( col-6, pa-2... )
        tabs: [...personalTabs, ...nonExportTabs],
        activeTab: null,
      },
      filters: _.cloneDeep(filters),
    },
    {
      selector: '#mainTable',
      options: {
        selecting: true,
        search: {
          function: searchInputing,
        },
        headerFixed: true,
        //url: 'https://dummyjson.com/users',
        url: 'get/pagination/personal_archive',
        title: 'Архив',
      },
      type: 'TableDefault',
      panel: {
        buttons: [
          {
            label: 'Обновить',
            class: ['v-table-button--custom'],
            url: '$IconEdit',
            function: consolePanel,
            backgroundColor: '#ffffff',
          },
        ],
      },
      head: [
        {
          title: 'id',
          type: 'default',
          align: 'center',
          fixed: {
            value: false,
            position: 'left',
          },
          sorts: [
            {
              type: 'string',
              default: '',
              value: '',
              isShow: false,
            },
          ],
          alias: 'p.id',
          isShow: true,
          width: '40',
          value: 'id',
          search: {
            field: '',
            isShow: true,
          },
        },
        {
          title: 'Статус',
          type: 'default',
          align: 'center',
          fixed: {
            value: false,
            position: 'left',
          },
          sorts: [
            {
              type: 'text',
              default: '',
              value: '',
              isShow: false,
            },
          ],
          isShow: true,
          width: '90',
          alias: 'ps.status',
          value: 'status_name',
          search: {
            field: '',
            isShow: true,
          },
        },
        {
          title: 'ФИО',
          type: 'default',
          align: 'center',
          fixed: {
            value: false,
            position: 'left',
          },
          sorts: [
            {
              type: 'string',
              default: '',
              value: '',
              isShow: false,
            },
          ],
          isShow: true,
          width: '150',
          alias: "CONCAT(p.surname, ' ', p.name_n, ' ', p.patronymic)",
          value: 'fio',
          search: {
            field: '',
            isShow: true,
          },
        },
        {
          title: 'Телефон',
          type: 'default',
          align: 'center',
          fixed: {
            value: false,
            position: undefined,
          },
          sorts: [
            {
              type: 'text',
              default: '',
              value: '',
              isShow: false,
            },
          ],
          isShow: true,
          width: '150',
          value: 'telefon',
          alias: 'p.telefon',
          search: {
            field: '',
            isShow: true,
          },
        },
        {
          title: 'Примечание',
          type: 'default',
          align: 'center',
          fixed: {
            value: false,
            position: undefined,
          },
          sorts: [
            {
              type: 'text',
              default: '',
              value: '',
              isShow: false,
            },
          ],
          isShow: true,
          width: '150',
          value: 'comment',
          alias: 'p.comment',
          search: {
            field: '',
            isShow: true,
          },
        },
        {
          title: 'Действия',
          type: 'actions',
          align: 'center',
          fixed: {
            value: false,
            position: 'right',
          },
          isShow: true,
          width: '100',
          value: 'actions',
          actions: [
            {
              type: 'button',
              url: '$IconSetting',
              function: consoleText,
              label: 'Редактировать',
            },
            {
              type: 'button',
              url: '$IconSetting',
              function: consoleButton,
              label: 'Удалить',
            },
          ],
        },
      ],
      data: {
        rows: [],
        totalRows: null,
        pageLength: 20,
        currentPage: 1,
        totalPages: null,
      },
      detail: {
        type: 'popup', // String 'popup' or 'page'
        classes: [''], // List class
        width: '1000px',
        method: 'get',
        alias: 'personal',
        url: '/get/form/',
        name: 'Персонал',
        bootstrapClass: [''], // List class from bootstrap ( col-6, pa-2... )
        tabs: [...personalTabs, ...nonExportTabs],
        activeTab: null,
      },
      filters: _.cloneDeep(filters),
    },
    {
      selector: '#mainTable',
      options: {
        selecting: true,
        search: {
          function: searchInputing,
        },
        headerFixed: true,
        //url: 'https://dummyjson.com/users',
        url: 'get/pagination/personal_on_add',
        title: 'На добавлении',
        contextMenu: contextMenuPersonal,
      },
      isShow: {
        condition: [
          {
            funcComputed: (context) => {
              const directions = context.store.state.user.direction_json
              return !(directions.length === 1 && directions.includes(7))
            },
          },
          {
            funcComputed: (context) => {
              const directions = context.store.state.user.direction_json
              return directions.includes(1)
            },
          },
        ],
      },
      type: 'TableDefault',
      panel: {
        buttons: [
          {
            label: 'Обновить',
            class: ['v-table-button--custom'],
            url: '$IconEdit',
            function: consolePanel,
            backgroundColor: '#ffffff',
          },
          // {
          //   label: 'Добавить',
          //   class: ['v-table-button--custom'],
          //   url: '$IconSetting',
          //   function: consolePanel,
          //   backgroundColor: '#fff',
          // },
          // {
          //   label: 'Скачать',
          //   class: ['v-table-button--custom'],
          //   function: consolePanel,
          //   backgroundColor: '#fff',
          // },
        ],
      },
      head: [
        {
          title: 'id',
          type: 'default',
          align: 'center',
          fixed: {
            value: false,
            position: 'left',
          },
          sorts: [
            {
              type: 'string',
              default: '',
              value: '',
              isShow: false,
            },
          ],
          alias: 'p.id',
          isShow: true,
          width: '40',
          value: 'id',
          search: {
            field: '',
            isShow: true,
          },
        },
        {
          title: 'Сотрудник',
          type: 'default',
          align: 'center',
          fixed: {
            value: false,
            position: 'left',
          },
          sorts: [
            {
              type: 'string',
              default: '',
              value: '',
              isShow: false,
            },
          ],
          isShow: true,
          width: '150',
          alias: "CONCAT(p.surname, ' ', p.name_n, ' ', p.patronymic)",
          value: 'personal_name',
          search: {
            field: '',
            isShow: true,
          },
        },
        {
          title: 'Статус',
          type: 'default',
          align: 'center',
          fixed: {
            value: false,
            position: 'left',
          },
          sorts: [
            {
              type: 'text',
              default: '',
              value: '',
              isShow: false,
            },
          ],
          isShow: true,
          width: '90',
          alias: 'ps.status',
          value: 'personal_status',
          search: {
            field: '',
            isShow: true,
          },
        },
        {
          title: 'Этап',
          type: 'default',
          align: 'center',
          fixed: {
            value: false,
            position: undefined,
          },
          sorts: [
            {
              type: 'text',
              default: '',
              value: '',
              isShow: false,
            },
          ],
          isShow: true,
          width: '150',
          value: 'task_type_name',
          alias: 'tt.name',
          search: {
            field: '',
            isShow: true,
          },
        },
        {
          title: 'От кого',
          type: 'default',
          align: 'center',
          fixed: {
            value: false,
            position: undefined,
          },
          sorts: [
            {
              type: 'text',
              default: '',
              value: '',
              isShow: false,
            },
          ],
          isShow: true,
          width: '150',
          value: 'from_account_name',
          alias: 'saf.name',
          search: {
            field: '',
            isShow: true,
          },
        },
        {
          title: 'У кого',
          type: 'default',
          align: 'center',
          fixed: {
            value: false,
            position: undefined,
          },
          sorts: [
            {
              type: 'text',
              default: '',
              value: '',
              isShow: false,
            },
          ],
          isShow: true,
          width: '150',
          value: 'to_account_name',
          alias: 'sat.name',
          search: {
            field: '',
            isShow: true,
          },
        },
      ],
      data: {
        rows: [],
        totalRows: null,
        pageLength: 20,
        currentPage: 1,
        totalPages: null,
      },
      detail: {
        type: 'popup', // String 'popup' or 'page'
        classes: [''], // List class
        width: '1000px',
        method: 'get',
        alias: 'personal',
        url: '/get/form/',
        name: 'Персонал',
        bootstrapClass: [''], // List class from bootstrap ( col-6, pa-2... )
        tabs: [...personalTabs, ...nonExportTabs],
        clearStore: true,
        activeTab: null,
        formData: {},
      },
    },
    {
      selector: '#mainTable',
      options: {
        selecting: true,
        search: {
          function: searchInputing,
        },
        headerFixed: true,
        //url: 'https://dummyjson.com/users',
        url: 'get/pagination/user_keys',
        title: 'Личные ключи',
        contextMenu: {
          actions: [
            {
              icon: '$IconDelete',
              label: 'Удалить',
              isShow: {
                condition: [
                  {
                    permissions: [1, 3, 4, 15],
                    type: true,
                  },
                ],
              },
              action: {
                type: 'confirm',
                dialog: {
                  text: 'Вы подтверждаете удаление ключа?',
                  function: async (context) => {
                    await context.store.dispatch('form/update', {
                      url: 'set/data/user_keys',
                      body: { data: { id: context.data.row.id, del: 1 } },
                    })
                  },
                },
              },
            },
          ],
        },
      },
      type: 'TableDefault',
      panel: {
        buttons: [
          {
            label: 'Обновить',
            class: ['v-table-button--custom'],
            url: '$IconEdit',
            function: consolePanel,
            backgroundColor: '#ffffff',
          },
          {
            label: 'Добавить',
            class: ['v-table-button--custom'],
            type: 'changeUrl',
            url: 'personal-add-key',
            backgroundColor: '#fff',
            isShow: {
              condition: [
                {
                  permissions: [1, 9],
                  type: true,
                },
              ],
            },
          },
          // {
          //   label: 'Скачать',
          //   class: ['v-table-button--custom'],
          //   function: consolePanel,
          //   backgroundColor: '#fff',
          // },
        ],
      },
      head: [
        {
          title: 'Ключ',
          type: 'default',
          align: 'center',
          fixed: {
            value: false,
            position: 'left',
          },
          sorts: [
            {
              type: 'string',
              default: '',
              value: '',
              isShow: false,
            },
          ],
          alias: 'uk.user_key',
          isShow: true,
          width: '40',
          value: 'user_key',
          search: {
            field: '',
            isShow: true,
          },
        },
        {
          title: 'ФИО',
          type: 'default',
          align: 'center',
          fixed: {
            value: false,
            position: 'left',
          },
          sorts: [
            {
              type: 'text',
              default: '',
              value: '',
              isShow: false,
            },
          ],
          isShow: true,
          width: '90',
          alias: 'uk.fio',
          value: 'fio',
          search: {
            field: '',
            isShow: true,
          },
        },
        {
          title: 'Линейщик',
          type: 'default',
          align: 'center',
          fixed: {
            value: false,
            position: 'left',
          },
          sorts: [
            {
              type: 'string',
              default: '',
              value: '',
              isShow: false,
            },
          ],
          isShow: true,
          width: '150',
          alias: "CONCAT(p.surname, ' ', p.name_n, ' ', p.patronymic)",
          value: 'personal_name',
          search: {
            field: '',
            isShow: true,
          },
        },
        {
          title: 'Объект',
          type: 'default',
          align: 'center',
          fixed: {
            value: false,
            position: undefined,
          },
          sorts: [
            {
              type: 'text',
              default: '',
              value: '',
              isShow: false,
            },
          ],
          isShow: true,
          width: '150',
          value: 'object_name',
          alias: 'o.name',
          search: {
            field: '',
            isShow: true,
          },
        },
        {
          title: 'Тип',
          type: 'default',
          align: 'center',
          fixed: {
            value: false,
            position: undefined,
          },
          sorts: [
            {
              type: 'text',
              default: '',
              value: '',
              isShow: false,
            },
          ],
          isShow: true,
          width: '150',
          value: 'is_stager',
          alias: 'uk.is_stager',
          search: {
            field: '',
            isShow: true,
          },
        },
        {
          title: 'Действия',
          type: 'actions',
          align: 'center',
          fixed: {
            value: false,
            position: 'right',
          },
          isShow: true,
          width: '100',
          value: 'actions',
          actions: [
            {
              type: 'button',
              url: '$IconSetting',
              function: consoleText,
              label: 'Редактировать',
            },
            {
              type: 'button',
              url: '$IconSetting',
              function: consoleButton,
              label: 'Удалить',
            },
          ],
        },
      ],
      data: {
        rows: [],
        totalRows: null,
        pageLength: 20,
        currentPage: 1,
        totalPages: null,
      },
      detail: {
        type: 'popup', // String 'popup' or 'page'
        classes: [''], // List class
        width: '550px',
        method: 'get',
        alias: 'user_keys',
        url: '/get/form/',
        name: 'Личные ключи',
        bootstrapClass: [''], // List class from bootstrap ( col-6, pa-2... )
        tabs: [formKeyAdd, formKeyEdit],
        activeTab: null,
      },
      isShow: {
        condition: [
          {
            permissions: [16, 19],
            type: false,
          },
          {
            funcComputed: (context) => {
              const directions = context.store.state.user.direction_json
              return !(directions.length === 1 && directions.includes(7))
            },
          },
          {
            funcComputed: (context) => {
              const directions = context.store.state.user.direction_json
              return directions.includes(1)
            },
          },
          // {
          //   direction_id: [2],
          //   type: false,
          // },
        ],
      },
      filters: filtersKey,
    },
    {
      selector: '#mainTable',
      options: {
        selecting: true,
        search: {
          function: searchInputing,
        },
        headerFixed: true,
        //url: 'https://dummyjson.com/users',
        url: 'get/pagination/employment_personal',
        title: 'Трудоустройства',
      },
      type: 'TableDefault',
      panel: {
        buttons: [
          {
            label: 'Обновить',
            class: ['v-table-button--custom'],
            url: '$IconEdit',
            function: consolePanel,
            backgroundColor: '#ffffff',
          },
        ],
      },
      head: [
        {
          title: 'Линейщик',
          type: 'default',
          align: 'center',
          fixed: {
            value: false,
            position: 'left',
          },
          sorts: [
            {
              type: 'text',
              default: '',
              value: '',
              isShow: false,
            },
          ],
          isShow: true,
          width: '90',
          alias: "CONCAT(p.surname, ' ', p.name_n, ' ', p.patronymic)",
          value: 'personal_name',
          search: {
            field: '',
            isShow: true,
          },
        },
        {
          title: 'Юридическое название',
          type: 'default',
          align: 'center',
          fixed: {
            value: false,
            position: 'left',
          },
          sorts: [
            {
              type: 'string',
              default: '',
              value: '',
              isShow: false,
            },
          ],
          isShow: true,
          width: '150',
          alias: "CONCAT(p.surname, ' ', p.name_n, ' ', p.patronymic)",
          value: 'juridical_name',
          search: {
            field: '',
            isShow: true,
          },
        },
        {
          title: 'Дата трудоустройства',
          type: 'default',
          align: 'center',
          fixed: {
            value: false,
            position: undefined,
          },
          sorts: [
            {
              type: 'text',
              default: '',
              value: '',
              isShow: false,
            },
          ],
          isShow: true,
          width: '150',
          value: 'date_start',
          alias: 'm.max_date',
          search: {
            field: '',
            isShow: true,
          },
        },
        {
          title: 'Дата увольнения',
          type: 'default',
          align: 'center',
          fixed: {
            value: false,
            position: undefined,
          },
          sorts: [
            {
              type: 'text',
              default: '',
              value: '',
              isShow: false,
            },
          ],
          isShow: true,
          width: '150',
          value: 'date_end',
          alias: 'hep.date_end',
          search: {
            field: '',
            isShow: true,
          },
        },
        {
          title: 'Дата редактирования',
          type: 'default',
          align: 'center',
          fixed: {
            value: false,
            position: undefined,
          },
          sorts: [
            {
              type: 'text',
              default: '',
              value: '',
              isShow: false,
            },
          ],
          isShow: true,
          width: '150',
          value: 'date_edit',
          alias: 'l.date_edit',
          search: {
            field: '',
            isShow: true,
          },
        },
      ],
      data: {
        rows: [],
        totalRows: null,
        pageLength: 20,
        currentPage: 1,
        totalPages: null,
      },
      detail: null,
      isShow: {
        condition: [
          {
            permissions: [4, 12, 22],
            type: true,
          },
        ],
      },
      // filters: filtersKey,
    },
    {
      selector: '#mainTable',
      options: {
        selecting: true,
        search: {
          function: searchInputing,
        },
        headerFixed: true,
        url: 'get/pagination/search_retail_personal',
        title: 'Поиск',
        contextMenu: contextMenuPersonal,
      },
      type: 'TableDefault',
      isShow: {
        condition: [
          {
            funcComputed: (context) => {
              return (
                [1, 9].includes(context.store.state.user.permission_id) &&
                context.store.state.user.direction_json.includes(2)
              )
            },
          },
        ],
      },
      panel: {
        buttons: [
          {
            label: 'Обновить',
            class: ['v-table-button--custom'],
            url: '$IconEdit',
            function: consolePanel,
            backgroundColor: '#ffffff',
          },
        ],
      },
      head: [
        {
          title: 'id',
          type: 'default',
          align: 'center',
          fixed: {
            value: false,
            position: 'left',
          },
          sorts: [
            {
              type: 'string',
              default: '',
              value: '',
              isShow: false,
            },
          ],
          alias: 'p.id',
          isShow: true,
          width: '40',
          value: 'id',
          search: {
            field: '',
            isShow: true,
          },
        },
        {
          title: 'Статус',
          type: 'default',
          align: 'center',
          fixed: {
            value: false,
            position: 'left',
          },
          sorts: [
            {
              type: 'text',
              default: '',
              value: '',
              isShow: false,
            },
          ],
          isShow: true,
          width: '90',
          alias: 'ps.status',
          value: 'status_name',
          search: {
            field: '',
            isShow: true,
          },
        },
        {
          title: 'ФИО',
          type: 'default',
          align: 'center',
          fixed: {
            value: false,
            position: 'left',
          },
          sorts: [
            {
              type: 'string',
              default: '',
              value: '',
              isShow: false,
            },
          ],
          isShow: true,
          width: '150',
          alias: "TRIM(CONCAT(p.surname, ' ', p.name_n, ' ', p.patronymic))",
          value: 'name',
          search: {
            field: '',
            isShow: true,
          },
        },
        {
          title: 'Телефон',
          type: 'default',
          align: 'center',
          fixed: {
            value: false,
            position: undefined,
          },
          sorts: [
            {
              type: 'text',
              default: '',
              value: '',
              isShow: false,
            },
          ],
          isShow: true,
          width: '150',
          value: 'telefon',
          alias: 'p.telefon',
          search: {
            field: '',
            isShow: true,
          },
        },
        {
          title: 'Примечание',
          type: 'default',
          align: 'center',
          fixed: {
            value: false,
            position: undefined,
          },
          sorts: [
            {
              type: 'text',
              default: '',
              value: '',
              isShow: false,
            },
          ],
          isShow: true,
          width: '150',
          value: 'comment',
          alias: 'p.comment',
          search: {
            field: '',
            isShow: true,
          },
        },
      ],
      data: {
        rows: [],
        totalRows: null,
        pageLength: 20,
        currentPage: 1,
        totalPages: null,
      },
      detail: {
        type: 'popup', // String 'popup' or 'page'
        classes: [''], // List class
        width: '1000px',
        method: 'get',
        alias: 'personal',
        url: '/get/form/',
        name: 'Персонал',
        bootstrapClass: [''], // List class from bootstrap ( col-6, pa-2... )
        tabs: [...personalTabs, ...nonExportTabs],
        clearStore: true,
        activeTab: null,
        formData: {},
      },
    },
  ],
}

export default config
